html {
    height: 100vh;
    background-size: 10px 10px;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.full-width {
    width: 100%;
}

.bg-dotted {
    background-image: url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%239c27b0' fill-opacity='0.2'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    background-size: 10px 10px;
}

.tosss {
    font-family: 'Birthstone Bounce', cursive;
    font-weight: 500;
    font-size: 5em;
    text-align: center;
    color: #9c27b0;
    transform: rotate(-3deg);
}

.footer {
    padding-top: 2rem;
    padding-bottom: 1rem
}

.footer a {
    text-decoration: none;
}

.hline {
    border-top: 1px solid #e0e0e0;
}
